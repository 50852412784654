import styled from "styled-components"
import { device } from "breakpoints"
import { Teaser } from "../../UI"
import { Title, InfoWrapper, Tag, TeaserImg } from "../../UI/Teaser/style"

export const Heading = styled.h2`
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 110%;
  letter-spacing: -0.01em;

  @media ${device.tablet} {
    font-size: 36px;
  }
`

export const ExtendedTeaser = styled(Teaser)`
  ${TeaserImg} {
    border-radius: 4px;
    img {
      transform: translateZ(0) scale(0.999999);
    }
  }

  ${InfoWrapper} {
    flex-direction: column-reverse;
  }

  ${Tag} {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 150%;
    color: ${(props) => props.theme.primaryColor};
    margin-top: 5px;
    margin-bottom: 7px;

    @media ${device.desktop} {
      font-size: 14px;
      line-height: 150%;
      margin-top: 10px;
    }
  }

  ${Title} {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 125%;
    letter-spacing: -0.01em;
    color: #575a5e;
    margin-bottom: 25px;

    @media ${device.desktop} {
      font-size: 20px;
      margin-bottom: 20px;
      line-height: 150%;
    }
  }

  :hover {
    ${Title} {
      color: #1870b0;
    }
  }
`
